import React, { useCallback, useRef } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core"; //Tipagem para o form
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";

import api from "../../services/api";
import { useToast } from "../../hooks/toast";

import logoImg from "../../assets/logo.svg";

import { FiArrowLeft, FiUser, FiMail, FiLock } from "react-icons/fi";
import Button from "../../components/Button";
import Input from "../../components/Input";

import { Container, Content, Background, AnimationContainer } from "./styles";
import getValidationErros from "../../utils/getValidationErrors";

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup.string().required("Nome obrigatório"),
          email: yup.string().required("E-mail obrigatório").email("Digite um e-mail válido"),
          password: yup.string().min(6, "No mínimo 6 digitos"),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post("/users", data);

        addToast({
          type: "success",
          title: "Cadastro realizado!",
          description: "Você já pode realizar seu logon no GoBarber!",
        });

        history.push("/");
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErros(err);
          console.log(err);
          formRef.current?.setErrors(errors);

          return;
        }

        console.log(err);

        addToast({
          type: "error",
          title: "Erro no cadastro",
          description: "Ocorreu um erro ao realizar o cadastro, tente novamente.",
        });
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="GoBarber"></img>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu cadastro</h1>

            <Input name="name" icon={FiUser} placeholder="Nome"></Input>

            <Input name="email" icon={FiMail} placeholder="E-mail"></Input>

            <Input name="password" type="password" icon={FiLock} placeholder="Senha"></Input>

            <Button type="submit"> Cadastrar </Button>
          </Form>

          <Link to="/">
            <FiArrowLeft />
            Volar para logon
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
