import React, { useCallback, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as yup from "yup";
import { Link } from "react-router-dom";

import { useToast } from "../../hooks/toast";
import getValidationErros from "../../utils/getValidationErrors";

import { FiLogIn, FiMail } from "react-icons/fi";
import logoImg from "../../assets/logo.svg";
import Button from "../../components/Button";
import Input from "../../components/Input";

import { Container, Content, Background, AnimationContainer } from "./styles";
import api from "../../services/api";

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  // const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          email: yup.string().required("E-mail obrigatório").email("Digite um e-mail válido"),
        });

        await schema.validate(data, { abortEarly: false });

        // recuperação de senha

        await api.post("/password/forgot", {
          email: data.email,
        });

        addToast({
          type: "success",
          title: "E-mail de recuperação enviado",
          description: "Enviamos um e-mail para confirmar sua recuperação de senha.",
        });

        // history.push("/dashboard");
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErros(err);
          console.log(err);
          formRef.current?.setErrors(errors);

          return;
        }

        console.log(err);

        addToast({
          type: "error",
          title: "Erro na recuperação de senha",
          description: "Ocorreu um erro ao tentar realizar a recuperação de senha.",
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast]
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="GoBarber"></img>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Recuperar senha</h1>

            <Input name="email" icon={FiMail} placeholder="E-mail"></Input>

            <Button loading={loading} type="submit"> Recuperar </Button>
          </Form>

          <Link to="/">
            <FiLogIn />
            Voltar ao login
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ForgotPassword;
