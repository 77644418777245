import React, { useCallback, useRef, ChangeEvent } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core"; //Tipagem para o form
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";

import api from "../../services/api";
import { useToast } from "../../hooks/toast";

import { FiUser, FiMail, FiLock, FiCamera, FiArrowLeft } from "react-icons/fi";
import Button from "../../components/Button";
import Input from "../../components/Input";

import { Container, Content, AvatarInput } from "./styles";
import getValidationErros from "../../utils/getValidationErrors";
import { useAuth } from "../../hooks/auth";

interface ProfileFormData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { user, updateUser } = useAuth();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup.string().required("Nome obrigatório"),
          email: yup
            .string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
          old_password: yup.string(),
          password: yup.string().when("old_password", {
            is: (val) => !!val.length,
            then: yup.string().required("Campo obrigatório"),
            otherwise: yup.string(),
          }),
          password_confirmation: yup
            .string()
            .when("old_password", {
              is: (val) => !!val.length,
              then: yup.string().required("Campo obrigatório"),
              otherwise: yup.string(),
            })
            .oneOf([yup.ref("password"), undefined], "Confirmação incorreta"),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = Object.assign(
          {
            name: name,
            email: email,
          },
          data.old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}
        );

        const response = await api.put("/profile", formData);

        updateUser(response.data);

        history.push("/dashboard");

        addToast({
          type: "success",
          title: "Perfil atualizado",
          description:
            "Suas informações do perfil foram atualizadas com sucesso.",
        });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErros(err);
          console.log(err);
          formRef.current?.setErrors(errors);

          return;
        }

        console.log(err);

        addToast({
          type: "error",
          title: "Erro na atualização",
          description:
            "Ocorreu um erro ao atualizar o perfil, tente novamente.",
        });
      }
    },
    [addToast, history, updateUser]
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append("avatar", e.target.files[0]);

        api.post("/users/avatar", data).then((response) => {
          updateUser(response.data);

          addToast({
            type: "success",
            title: "Avatar atualizado",
          });
        });
        console.log(e.target.files[0]);
      }
    },
    [addToast, updateUser]
  );

  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft></FiArrowLeft>
          </Link>
        </div>
      </header>

      <Content>
        <Form
          ref={formRef}
          initialData={{ name: user.name, email: user.email }}
          onSubmit={handleSubmit}
        >
          <AvatarInput>
            <img src={user.avatar_url} alt={user.name} />

            <label htmlFor="avatar">
              <FiCamera></FiCamera>
              <input type="file" id="avatar" onChange={handleAvatarChange} />
            </label>
          </AvatarInput>

          <h1>Meu Perfil</h1>

          <Input name="name" icon={FiUser} placeholder="Nome"></Input>

          <Input name="email" icon={FiMail} placeholder="E-mail"></Input>

          <Input
            containerStyle={{ marginTop: 24 }}
            name="old_password"
            type="password"
            icon={FiLock}
            placeholder="Senha atual"
          ></Input>
          <Input
            name="password"
            type="password"
            icon={FiLock}
            placeholder="Nova senha"
          ></Input>
          <Input
            name="password_confirmation"
            type="password"
            icon={FiLock}
            placeholder="Confirmar senha"
          ></Input>

          <Button type="submit"> Confirmar mudanças </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
